/**
 * @fileoverview gRPC-Web generated client stub for market
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.21.12
// source: market/limitOrderStateController.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var ledger_token_pb = require('../ledger/token_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var ledger_amount_pb = require('../ledger/amount_pb.js')

var market_limitOrder_pb = require('../market/limitOrder_pb.js')
const proto = {};
proto.market = require('./limitOrderStateController_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.market.LimitOrderStateControllerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.market.LimitOrderStateControllerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.market.SubmitLimitOrderRequest,
 *   !proto.market.SubmitLimitOrderResponse>}
 */
const methodDescriptor_LimitOrderStateController_SubmitLimitOrder = new grpc.web.MethodDescriptor(
  '/market.LimitOrderStateController/SubmitLimitOrder',
  grpc.web.MethodType.UNARY,
  proto.market.SubmitLimitOrderRequest,
  proto.market.SubmitLimitOrderResponse,
  /**
   * @param {!proto.market.SubmitLimitOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.market.SubmitLimitOrderResponse.deserializeBinary
);


/**
 * @param {!proto.market.SubmitLimitOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.market.SubmitLimitOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.market.SubmitLimitOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.market.LimitOrderStateControllerClient.prototype.submitLimitOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/market.LimitOrderStateController/SubmitLimitOrder',
      request,
      metadata || {},
      methodDescriptor_LimitOrderStateController_SubmitLimitOrder,
      callback);
};


/**
 * @param {!proto.market.SubmitLimitOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.market.SubmitLimitOrderResponse>}
 *     Promise that resolves to the response
 */
proto.market.LimitOrderStateControllerPromiseClient.prototype.submitLimitOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/market.LimitOrderStateController/SubmitLimitOrder',
      request,
      metadata || {},
      methodDescriptor_LimitOrderStateController_SubmitLimitOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.market.MarkLimitOrderSubmissionFailedRequest,
 *   !proto.market.MarkLimitOrderSubmissionFailedResponse>}
 */
const methodDescriptor_LimitOrderStateController_MarkLimitOrderSubmissionFailed = new grpc.web.MethodDescriptor(
  '/market.LimitOrderStateController/MarkLimitOrderSubmissionFailed',
  grpc.web.MethodType.UNARY,
  proto.market.MarkLimitOrderSubmissionFailedRequest,
  proto.market.MarkLimitOrderSubmissionFailedResponse,
  /**
   * @param {!proto.market.MarkLimitOrderSubmissionFailedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.market.MarkLimitOrderSubmissionFailedResponse.deserializeBinary
);


/**
 * @param {!proto.market.MarkLimitOrderSubmissionFailedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.market.MarkLimitOrderSubmissionFailedResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.market.MarkLimitOrderSubmissionFailedResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.market.LimitOrderStateControllerClient.prototype.markLimitOrderSubmissionFailed =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/market.LimitOrderStateController/MarkLimitOrderSubmissionFailed',
      request,
      metadata || {},
      methodDescriptor_LimitOrderStateController_MarkLimitOrderSubmissionFailed,
      callback);
};


/**
 * @param {!proto.market.MarkLimitOrderSubmissionFailedRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.market.MarkLimitOrderSubmissionFailedResponse>}
 *     Promise that resolves to the response
 */
proto.market.LimitOrderStateControllerPromiseClient.prototype.markLimitOrderSubmissionFailed =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/market.LimitOrderStateController/MarkLimitOrderSubmissionFailed',
      request,
      metadata || {},
      methodDescriptor_LimitOrderStateController_MarkLimitOrderSubmissionFailed);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.market.MarkLimitOrderCompleteRequest,
 *   !proto.market.MarkLimitOrderCompleteResponse>}
 */
const methodDescriptor_LimitOrderStateController_MarkLimitOrderComplete = new grpc.web.MethodDescriptor(
  '/market.LimitOrderStateController/MarkLimitOrderComplete',
  grpc.web.MethodType.UNARY,
  proto.market.MarkLimitOrderCompleteRequest,
  proto.market.MarkLimitOrderCompleteResponse,
  /**
   * @param {!proto.market.MarkLimitOrderCompleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.market.MarkLimitOrderCompleteResponse.deserializeBinary
);


/**
 * @param {!proto.market.MarkLimitOrderCompleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.market.MarkLimitOrderCompleteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.market.MarkLimitOrderCompleteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.market.LimitOrderStateControllerClient.prototype.markLimitOrderComplete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/market.LimitOrderStateController/MarkLimitOrderComplete',
      request,
      metadata || {},
      methodDescriptor_LimitOrderStateController_MarkLimitOrderComplete,
      callback);
};


/**
 * @param {!proto.market.MarkLimitOrderCompleteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.market.MarkLimitOrderCompleteResponse>}
 *     Promise that resolves to the response
 */
proto.market.LimitOrderStateControllerPromiseClient.prototype.markLimitOrderComplete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/market.LimitOrderStateController/MarkLimitOrderComplete',
      request,
      metadata || {},
      methodDescriptor_LimitOrderStateController_MarkLimitOrderComplete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.market.MarkLimitOrderOpenRequest,
 *   !proto.market.MarkLimitOrderOpenResponse>}
 */
const methodDescriptor_LimitOrderStateController_MarkLimitOrderOpen = new grpc.web.MethodDescriptor(
  '/market.LimitOrderStateController/MarkLimitOrderOpen',
  grpc.web.MethodType.UNARY,
  proto.market.MarkLimitOrderOpenRequest,
  proto.market.MarkLimitOrderOpenResponse,
  /**
   * @param {!proto.market.MarkLimitOrderOpenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.market.MarkLimitOrderOpenResponse.deserializeBinary
);


/**
 * @param {!proto.market.MarkLimitOrderOpenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.market.MarkLimitOrderOpenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.market.MarkLimitOrderOpenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.market.LimitOrderStateControllerClient.prototype.markLimitOrderOpen =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/market.LimitOrderStateController/MarkLimitOrderOpen',
      request,
      metadata || {},
      methodDescriptor_LimitOrderStateController_MarkLimitOrderOpen,
      callback);
};


/**
 * @param {!proto.market.MarkLimitOrderOpenRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.market.MarkLimitOrderOpenResponse>}
 *     Promise that resolves to the response
 */
proto.market.LimitOrderStateControllerPromiseClient.prototype.markLimitOrderOpen =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/market.LimitOrderStateController/MarkLimitOrderOpen',
      request,
      metadata || {},
      methodDescriptor_LimitOrderStateController_MarkLimitOrderOpen);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.market.CompleteLimitOrderRequest,
 *   !proto.market.CompleteLimitOrderResponse>}
 */
const methodDescriptor_LimitOrderStateController_CompleteLimitOrder = new grpc.web.MethodDescriptor(
  '/market.LimitOrderStateController/CompleteLimitOrder',
  grpc.web.MethodType.UNARY,
  proto.market.CompleteLimitOrderRequest,
  proto.market.CompleteLimitOrderResponse,
  /**
   * @param {!proto.market.CompleteLimitOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.market.CompleteLimitOrderResponse.deserializeBinary
);


/**
 * @param {!proto.market.CompleteLimitOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.market.CompleteLimitOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.market.CompleteLimitOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.market.LimitOrderStateControllerClient.prototype.completeLimitOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/market.LimitOrderStateController/CompleteLimitOrder',
      request,
      metadata || {},
      methodDescriptor_LimitOrderStateController_CompleteLimitOrder,
      callback);
};


/**
 * @param {!proto.market.CompleteLimitOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.market.CompleteLimitOrderResponse>}
 *     Promise that resolves to the response
 */
proto.market.LimitOrderStateControllerPromiseClient.prototype.completeLimitOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/market.LimitOrderStateController/CompleteLimitOrder',
      request,
      metadata || {},
      methodDescriptor_LimitOrderStateController_CompleteLimitOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.market.CancelLimitOrderRequest,
 *   !proto.market.CancelLimitOrderResponse>}
 */
const methodDescriptor_LimitOrderStateController_CancelLimitOrder = new grpc.web.MethodDescriptor(
  '/market.LimitOrderStateController/CancelLimitOrder',
  grpc.web.MethodType.UNARY,
  proto.market.CancelLimitOrderRequest,
  proto.market.CancelLimitOrderResponse,
  /**
   * @param {!proto.market.CancelLimitOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.market.CancelLimitOrderResponse.deserializeBinary
);


/**
 * @param {!proto.market.CancelLimitOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.market.CancelLimitOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.market.CancelLimitOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.market.LimitOrderStateControllerClient.prototype.cancelLimitOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/market.LimitOrderStateController/CancelLimitOrder',
      request,
      metadata || {},
      methodDescriptor_LimitOrderStateController_CancelLimitOrder,
      callback);
};


/**
 * @param {!proto.market.CancelLimitOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.market.CancelLimitOrderResponse>}
 *     Promise that resolves to the response
 */
proto.market.LimitOrderStateControllerPromiseClient.prototype.cancelLimitOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/market.LimitOrderStateController/CancelLimitOrder',
      request,
      metadata || {},
      methodDescriptor_LimitOrderStateController_CancelLimitOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.market.MarkLimitOrderCancelledRequest,
 *   !proto.market.MarkLimitOrderCancelledResponse>}
 */
const methodDescriptor_LimitOrderStateController_MarkLimitOrderCancelled = new grpc.web.MethodDescriptor(
  '/market.LimitOrderStateController/MarkLimitOrderCancelled',
  grpc.web.MethodType.UNARY,
  proto.market.MarkLimitOrderCancelledRequest,
  proto.market.MarkLimitOrderCancelledResponse,
  /**
   * @param {!proto.market.MarkLimitOrderCancelledRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.market.MarkLimitOrderCancelledResponse.deserializeBinary
);


/**
 * @param {!proto.market.MarkLimitOrderCancelledRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.market.MarkLimitOrderCancelledResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.market.MarkLimitOrderCancelledResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.market.LimitOrderStateControllerClient.prototype.markLimitOrderCancelled =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/market.LimitOrderStateController/MarkLimitOrderCancelled',
      request,
      metadata || {},
      methodDescriptor_LimitOrderStateController_MarkLimitOrderCancelled,
      callback);
};


/**
 * @param {!proto.market.MarkLimitOrderCancelledRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.market.MarkLimitOrderCancelledResponse>}
 *     Promise that resolves to the response
 */
proto.market.LimitOrderStateControllerPromiseClient.prototype.markLimitOrderCancelled =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/market.LimitOrderStateController/MarkLimitOrderCancelled',
      request,
      metadata || {},
      methodDescriptor_LimitOrderStateController_MarkLimitOrderCancelled);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.market.ResolveIDStateRequest,
 *   !proto.market.ResolveIDStateResponse>}
 */
const methodDescriptor_LimitOrderStateController_ResolveIDState = new grpc.web.MethodDescriptor(
  '/market.LimitOrderStateController/ResolveIDState',
  grpc.web.MethodType.UNARY,
  proto.market.ResolveIDStateRequest,
  proto.market.ResolveIDStateResponse,
  /**
   * @param {!proto.market.ResolveIDStateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.market.ResolveIDStateResponse.deserializeBinary
);


/**
 * @param {!proto.market.ResolveIDStateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.market.ResolveIDStateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.market.ResolveIDStateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.market.LimitOrderStateControllerClient.prototype.resolveIDState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/market.LimitOrderStateController/ResolveIDState',
      request,
      metadata || {},
      methodDescriptor_LimitOrderStateController_ResolveIDState,
      callback);
};


/**
 * @param {!proto.market.ResolveIDStateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.market.ResolveIDStateResponse>}
 *     Promise that resolves to the response
 */
proto.market.LimitOrderStateControllerPromiseClient.prototype.resolveIDState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/market.LimitOrderStateController/ResolveIDState',
      request,
      metadata || {},
      methodDescriptor_LimitOrderStateController_ResolveIDState);
};


module.exports = proto.market;

