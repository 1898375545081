/**
 * @fileoverview gRPC-Web generated client stub for marketLimitOrderView
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.21.12
// source: views/marketLimitOrderView/modelUpdater.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../../security/context_pb.js')

var market_limitOrder_pb = require('../../market/limitOrder_pb.js')
const proto = {};
proto.marketLimitOrderView = require('./modelUpdater_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.marketLimitOrderView.ModelUpdaterClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.marketLimitOrderView.ModelUpdaterPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.marketLimitOrderView.UpdateForLimitOrderIDRequest,
 *   !proto.marketLimitOrderView.UpdateForLimitOrderIDResponse>}
 */
const methodDescriptor_ModelUpdater_UpdateForLimitOrderID = new grpc.web.MethodDescriptor(
  '/marketLimitOrderView.ModelUpdater/UpdateForLimitOrderID',
  grpc.web.MethodType.UNARY,
  proto.marketLimitOrderView.UpdateForLimitOrderIDRequest,
  proto.marketLimitOrderView.UpdateForLimitOrderIDResponse,
  /**
   * @param {!proto.marketLimitOrderView.UpdateForLimitOrderIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.marketLimitOrderView.UpdateForLimitOrderIDResponse.deserializeBinary
);


/**
 * @param {!proto.marketLimitOrderView.UpdateForLimitOrderIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.marketLimitOrderView.UpdateForLimitOrderIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.marketLimitOrderView.UpdateForLimitOrderIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.marketLimitOrderView.ModelUpdaterClient.prototype.updateForLimitOrderID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/marketLimitOrderView.ModelUpdater/UpdateForLimitOrderID',
      request,
      metadata || {},
      methodDescriptor_ModelUpdater_UpdateForLimitOrderID,
      callback);
};


/**
 * @param {!proto.marketLimitOrderView.UpdateForLimitOrderIDRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.marketLimitOrderView.UpdateForLimitOrderIDResponse>}
 *     Promise that resolves to the response
 */
proto.marketLimitOrderView.ModelUpdaterPromiseClient.prototype.updateForLimitOrderID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/marketLimitOrderView.ModelUpdater/UpdateForLimitOrderID',
      request,
      metadata || {},
      methodDescriptor_ModelUpdater_UpdateForLimitOrderID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.marketLimitOrderView.UpdateForLimitOrderRequest,
 *   !proto.marketLimitOrderView.UpdateForLimitOrderResponse>}
 */
const methodDescriptor_ModelUpdater_UpdateForLimitOrder = new grpc.web.MethodDescriptor(
  '/marketLimitOrderView.ModelUpdater/UpdateForLimitOrder',
  grpc.web.MethodType.UNARY,
  proto.marketLimitOrderView.UpdateForLimitOrderRequest,
  proto.marketLimitOrderView.UpdateForLimitOrderResponse,
  /**
   * @param {!proto.marketLimitOrderView.UpdateForLimitOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.marketLimitOrderView.UpdateForLimitOrderResponse.deserializeBinary
);


/**
 * @param {!proto.marketLimitOrderView.UpdateForLimitOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.marketLimitOrderView.UpdateForLimitOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.marketLimitOrderView.UpdateForLimitOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.marketLimitOrderView.ModelUpdaterClient.prototype.updateForLimitOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/marketLimitOrderView.ModelUpdater/UpdateForLimitOrder',
      request,
      metadata || {},
      methodDescriptor_ModelUpdater_UpdateForLimitOrder,
      callback);
};


/**
 * @param {!proto.marketLimitOrderView.UpdateForLimitOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.marketLimitOrderView.UpdateForLimitOrderResponse>}
 *     Promise that resolves to the response
 */
proto.marketLimitOrderView.ModelUpdaterPromiseClient.prototype.updateForLimitOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/marketLimitOrderView.ModelUpdater/UpdateForLimitOrder',
      request,
      metadata || {},
      methodDescriptor_ModelUpdater_UpdateForLimitOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.marketLimitOrderView.FullUpdateRequest,
 *   !proto.marketLimitOrderView.FullUpdateResponse>}
 */
const methodDescriptor_ModelUpdater_FullUpdate = new grpc.web.MethodDescriptor(
  '/marketLimitOrderView.ModelUpdater/FullUpdate',
  grpc.web.MethodType.UNARY,
  proto.marketLimitOrderView.FullUpdateRequest,
  proto.marketLimitOrderView.FullUpdateResponse,
  /**
   * @param {!proto.marketLimitOrderView.FullUpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.marketLimitOrderView.FullUpdateResponse.deserializeBinary
);


/**
 * @param {!proto.marketLimitOrderView.FullUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.marketLimitOrderView.FullUpdateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.marketLimitOrderView.FullUpdateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.marketLimitOrderView.ModelUpdaterClient.prototype.fullUpdate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/marketLimitOrderView.ModelUpdater/FullUpdate',
      request,
      metadata || {},
      methodDescriptor_ModelUpdater_FullUpdate,
      callback);
};


/**
 * @param {!proto.marketLimitOrderView.FullUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.marketLimitOrderView.FullUpdateResponse>}
 *     Promise that resolves to the response
 */
proto.marketLimitOrderView.ModelUpdaterPromiseClient.prototype.fullUpdate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/marketLimitOrderView.ModelUpdater/FullUpdate',
      request,
      metadata || {},
      methodDescriptor_ModelUpdater_FullUpdate);
};


module.exports = proto.marketLimitOrderView;

